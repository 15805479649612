// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Base64 from "../bindings/Base64.bs.js";

function encodePosition(colNum, rowNum) {
  return Base64.encode(Math.imul(rowNum, 7) + colNum | 0, 1);
}

function decodePosition(str) {
  var id = Base64.decode(str);
  return [
          id % 7,
          id / 7 | 0
        ];
}

export {
  encodePosition ,
  decodePosition ,
  
}
/* No side effect */
