// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Lzy from "../util/Lzy.bs.js";
import * as Data9 from "./Data9.bs.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

var all = [
  "Set9_Bastion",
  "Set9_Bruiser",
  "Set9_Challenger",
  "Set9_Darkin",
  "Set9_Deadeye",
  "Set9_Demacia",
  "Set9_Empress",
  "Set9_Freljord",
  "Set9_Marksman",
  "Set9_Ionia",
  "Set9_Multicaster",
  "Set9_Noxus",
  "Set9_Preserver",
  "Set9_Rogue",
  "Set9_Shurima",
  "Set9_Armorclad",
  "Set9_Piltover",
  "Set9_Sorcerer",
  "Set9_Slayer",
  "Set9_Strategist",
  "Set9_Redeemer",
  "Set9_ShadowIsles",
  "Set9_Targon",
  "Set9_Technogenius",
  "Set9_Void",
  "Set9_Wanderer",
  "Set9_BandleCity",
  "Set9_Zaun"
];

function toApiName(prim) {
  return prim;
}

function fromApiNameSafe(v) {
  if (all.includes(v)) {
    return v;
  }
  
}

function toName(t) {
  return Belt_Array.getExn(t.split("_"), 1);
}

function fromApiName(apiName) {
  var v = fromApiNameSafe(apiName);
  if (v !== undefined) {
    return v;
  } else {
    return Js_exn.raiseError("Unknown api name " + apiName);
  }
}

var urlNameMap = Lzy.make(function (param) {
      var base = Belt_Array.map(all, (function (u) {
              return [
                      u,
                      Belt_Array.getExn(u.split("_"), 1).toLowerCase()
                    ];
            }));
      var map = Belt_MapInt.fromArray(base);
      var revMap = Belt_MapString.fromArray(Belt_Array.map(base, (function (param) {
                  return [
                          param[1],
                          param[0]
                        ];
                })));
      return [
              map,
              revMap
            ];
    });

function toUrlName(t) {
  var match = Lzy.get(urlNameMap);
  return Belt_Option.getExn(Belt_MapInt.get(match[0], t));
}

function fromUrlName(name) {
  var match = Lzy.get(urlNameMap);
  return Belt_MapString.get(match[1], name);
}

function getInfo(trait) {
  return Belt_Option.getExn(Js_dict.get(Data9.traits, trait));
}

function fromAugmentId(id) {
  switch (id) {
    case "9ArmorcladTrait" :
        return ["Set9_Armorclad"];
    case "9BastionTrait" :
        return ["Set9_Bastion"];
    case "9BruiserTrait" :
        return ["Set9_Bruiser"];
    case "9ChallengerTrait" :
        return ["Set9_Challenger"];
    case "9DeadeyeTrait" :
        return ["Set9_Deadeye"];
    case "9DemaciaTrait" :
        return ["Set9_Demacia"];
    case "9FreljordTrait" :
        return ["Set9_Freljord"];
    case "9IoniaTrait" :
        return ["Set9_Ionia"];
    case "9MarksmanTrait" :
        return ["Set9_Marksman"];
    case "9MulticasterTrait" :
        return ["Set9_Multicaster"];
    case "9NoxusTrait" :
        return ["Set9_Noxus"];
    case "9PiltoverTrait" :
        return ["Set9_Piltover"];
    case "9PreserverTrait" :
        return ["Set9_Preserver"];
    case "9RogueTrait" :
        return ["Set9_Rogue"];
    case "9ShadowIslesTrait" :
        return ["Set9_ShadowIsles"];
    case "9ShurimaTrait" :
        return ["Set9_Shurima"];
    case "9SlayerTrait" :
        return ["Set9_Slayer"];
    case "9SorcererTrait" :
        return ["Set9_Sorcerer"];
    case "9StrategistTrait" :
        return ["Set9_Strategist"];
    case "9TargonTrait" :
        return ["Set9_Targon"];
    case "9VoidTrait" :
    case "9VoidTrait2" :
        return ["Set9_Void"];
    case "9ZaunTrait" :
        return ["Set9_Zaun"];
    default:
      return [];
  }
}

function isUnique(t) {
  if (t === "Set9_Darkin" || t === "Set9_Redeemer" || t === "Set9_Wanderer" || t === "Set9_Empress") {
    return true;
  } else {
    return t === "Set9_Technogenius";
  }
}

export {
  all ,
  toApiName ,
  fromApiNameSafe ,
  toName ,
  fromApiName ,
  urlNameMap ,
  toUrlName ,
  fromUrlName ,
  getInfo ,
  fromAugmentId ,
  isUnique ,
  
}
/* urlNameMap Not a pure module */
